// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2 
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import '~flatpickr/dist/flatpickr.css';

// LeafLet Chart
@import 'leaflet/dist/leaflet.css';

// Emoji Picker
// @import '~@ctrl/ngx-emoji-mart/picker';
.emoji-mart {
    position: absolute;
    bottom: 20px;
    left: -80px;
}

.emoji-mart-preview {
    display: none;
}



// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.modal-backdrop {
    z-index: 1002 !important;
}

.dropdown-toggle::after {
    display: none;
}

.dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    // background: #fff !important;
    color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
    font-size: 30px;
    color: var(--bs-gray-600);
}

.star {
    position: relative;
    display: inline-block;
}

.star_fill {
    color: $gray-500;
}

.full {
    color: #b0c4de;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: var(--bs-gray-600);
}

.filled {
    color: #1e90ff;
}

.bad {
    color: #deb0b0;
}

.filled.bad {
    color: #ff1e1e;
}

.halfHeart {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: red;
}

// Calendar
.flatpickr-calendar.open {
    z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked>.switch-pane>span {
    color: #fff;
}

.switch.switch-small>.switch-pane>span {
    font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
    min-height: 245px !important;
}

.custom-accordion {
    .accordion-list {
        padding: 0px;
        background-color: transparent;
        margin-bottom: 20px
    }
}

[data-bs-theme="dark"] {
    .ng-select .ng-select-container {
        background-color: #2a3042 !important;
        color: #c3cbe4 !important;
        border-color: #353d55 !important;
    }

    .ng-dropdown-panel {
        background-color: #2a3042 !important;
        border-color: #353d55 !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: #2a3042 !important;
        border-color: #353d55 !important;

        color: #c3cbe4 !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: #393f55 !important;
        color: #d6dbeb !important;
    }
}

//dropzone
.dropzone>.dropzone.dz-wrapper .dz-message {
    background: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
    background-color: var(--#{$prefix}light) !important;
    border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
    color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background-color: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
    background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    background: $input-border-color !important;
}


// Dark Mode Css
[data-bs-theme="dark"] {
    .bs-datepicker {
        box-shadow: none !important;
    }

    .bs-datepicker-container {
        background-color: #32394e !important;

    }

    .bs-datepicker-head button {
        color: #c3cbe4 !important;
    }

    .bs-datepicker-body {
        border: 1px solid transparent;
    }

    .auth-logo .auth-logo-light {
        display: block;
    }

    .auth-logo .auth-logo-dark {
        display: none;
    }

    // Accordion
    .accordion {
        --bs-accordion-color: #fff;
        --bs-accordion-bg: transparent;
    }

    .accordion-button {
        color: var(--bs-body-color);
    }
}

.page-link {
    display: inline-table;
}


//   vertical tab

.vertical-tab {
    ul {
        display: inline-block;
        position: relative;
        z-index: 1;
        border: 0;

        @media (max-width: 1024px) {
            display: block;
        }

        li {
            a {
                padding: 8px 50px;

                @media (max-width: 1440px) {
                    padding: 8px 30px;
                }
            }
        }
    }

    .tab-content {
        position: absolute;
        top: 0;

        @media (max-width: 1024px) {
            position: inherit;
        }

    }
}


.ecommerce-checkout-tab {
    ul {
        display: inline-block;
        // float: left;
        margin-right: 20px;
        border: 0;

        @media (max-width: 600px) {
            display: block;
            float: inherit;
        }
    }

    li {
        a {
            padding: 1px 80px;
        }
    }
}

.product-detai-imgs {
    ul {
        border: 0;

        a {
            border: 0 !important;
            width: 130px;

            @media (max-width: 425px) {
                width: 50px;
            }
        }
    }
}

.tooltip,
.tooltip-arrow {
    position: absolute;
}

[data-layout-size="boxed"] {
    .vertical-tab {
        ul {
            display: inline-block;
            position: relative;
            z-index: 1;
            border: 0;

            @media (max-width: 1024px) {
                display: block;
            }

            li {
                a {
                    padding: 8px 50px;
                }
            }
        }
    }

}

.bs-datepicker-head {
    background-color: #ccc !important;
}

.bs-datepicker-head button.current {
    border: 1px solid #999;
    border-radius: 0;
    background: #fff;
}

.bs-datepicker-body table th {
    color: #757575 !important;
}

.bs-datepicker-body table td {
    padding: 2px;
}

.bs-datepicker-body table.days span {
    line-height: 20px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 0;
}

.bs-datepicker {
    box-shadow: none;
}

.bs-datepicker-body {
    padding: 5px;
}

.bs-datepicker table.days thead tr th:first-child,
.bs-datepicker table.days tbody tr td:first-child {
    display: none;
}

.bs-datepicker-body table td span.selected {
    background-color: #26A69A !important;
}

.bs-datepicker-head button {
    color: black !important;
}

.calend-bg i {
    color: #fff !important;
}

.bs-datepicker-container {
    padding: 0;
}

.calend-bg {
    background-color: #00BCD4;
}

.owl-theme .owl-nav [class*=owl-] {
    width: 30px;
    height: 30px;
    line-height: 28px !important;
    font-size: 30px !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    color: #ffff !important;
    justify-content: center !important;
    margin: 4px 8px !important;
    display: inline-flex !important;
    align-items: center !important;
}

.tab-bg {
    .nav-tabs {
        background-color: var(--#{$prefix}light);
        margin-bottom: 20px;
    }

}

table tbody tr:hover {
    background-color: rgba(0, 188, 212, 0.2);
}

// popover
.bs-popover-top,
.bs-popover-bottom {
    margin-left: -8px !important;
}

.bs-popover-left .arrow,
.bs-popover-right .arrow {
    margin-top: -8px !important;
}

.custom-widget-nav {
    .nav-tabs {
        justify-content: flex-end;
        margin-top: -1.5rem;
    }
}

.custom-vertical-tabs {
    display: flex;
    align-items: flex-start;
    gap: 16px;


    .nav-tabs {
        flex-direction: column;

        @media (min-width: 992px) {
            flex-shrink: 0;
        }
    }

    .tab-content {
        @media (min-width: 992px) {
            flex-grow: 1;
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;
        gap: 16px;

        .nav-tabs,
        .tab-content {
            width: 100%;
        }
    }
}


// nav-justify-center

.nav-justify-center {
    .nav-tabs {
        justify-content: center;
    }
}

.ngx-slider .ngx-slider-bar {
    background-color: var(--#{$prefix}tertiary-bg) !important;
}

#backdrop {
    &.offcanvas-backdrop {
        display: none;

        &.show {
            display: inline-block;
        }
    }
}

// Angular 17 CSS

// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.text-left {
    text-align: left;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mt-28 {
    margin-top: 28px;
}

.text-right {
    text-align: right;
}

table tr th,
table.dataTable thead th {
    text-align: center !important;
    color: #fff !important;
}

table tr th,
table tr td {
    white-space: nowrap;
}

.mandatory {
    color: #f46a6a;
}

.godown-accordion .card-header {
    background-color: #00BCD4;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.godown-accordion .card-header button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #fff;
}

.godown-accordion .card-header button:hover,
.godown-accordion .card-header button:focus {
    text-decoration: none;
}

.custom-form-group {
    border: 1px solid #dadada;
    border-right: none;
    padding: 5px 7px 0px 7px;
}

.custom-form-group:last-child {
    border-right: 1px solid #dadada;
}

.custom-form-group table td {
    padding-right: 10px;
}

.custom-form-group table td .custom-radio-btn {
    margin-top: 4px;
    margin-right: 5px;
}

.custom-form-group table td .custom-radio-label {
    margin-bottom: 3px;
}

.custom-form-group.border-none {
    border: none;
    border-right: none;
}

.view-page {
    margin-bottom: 10px;
}

.view-page .view-label {
    display: block;
    margin: 0 0 5px 0;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #eee;
    color: #666;
    font-weight: normal;
}

.btn:hover,
.btn:focus,
.btn.focus {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.view-page .view-content {
    font-weight: 500;
    color: #08A1B4;
}

.multi-scroll {
    height: 53px !important;
    overflow: auto;
    background-color: #f4f4f4;
}

.view-steps .step-indicator,
.view-steps li.current .step-indicator {
    background-color: #2ca67a;
    border: 2px solid #2ca67a !important;
}

.cust-dis-textarea {
    height: 93px !important;
    overflow: auto;
    background-color: #f4f4f4;
}

.custom-avatar-md {
    width: 100px;
    height: 50px;
}

.custom-border-right {
    border-right: 1px solid #556ee6;
}

.table-hover-color tbody tr:hover {
    background-color: #dcedfc;
}

// Theme New Design Changes
::-webkit-scrollbar {
    width: 5px !important;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: #999;
}

.btn-success,
.btn-success:hover {
    background: #4CAF50;
    border-color: #4CAF50;
}

.btn-primary,
.btn-primary:hover {
    background: #2196F3;
    border-color: #2196F3;
}

.text-primary {
    color: #2196F3 !important;
}

.btn-info,
.btn-info:hover {
    background: #00BCD4;
    border-color: #00BCD4;
}

.btn-secondary,
.btn-secondary:hover {
    background: #607D8B;
    border-color: #607D8B;
}

.btn-danger,
.btn-danger:hover {
    background: #F44336;
    border-color: #F44336;
}

.btn-warning,
.btn-warning:hover {
    background: #FF9800;
    border-color: #FF9800;
}

.bg-success {
    background-color: #4CAF50 !important;
    border-color: #4CAF50;
}

.bg-primary {
    background-color: #2196F3 !important;
    border-color: #2196F3;
}

.bg-info {
    background-color: #00BCD4;
    border-color: #00BCD4;
}

.bg-secondary {
    background-color: #e4e4e4 !important;
    border-color: #ddd;
}

.bg-danger {
    background-color: #F44336 !important;
    border-color: #F44336;
}

.bg-warning {
    background-color: #FF9800 !important;
    border-color: #FF9800;
}

a.btn-success.disabled,
a.btn-info.disabled,
a.btn-danger.disabled,
a.btn-primary.disabled,
input:disabled,
select:disabled,
button:disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

a.btn-success.disabled {
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;
    opacity: 0.6 !important;
}

a.btn-info.disabled {
    background-color: #00BCD4 !important;
    border-color: #00BCD4 !important;
    opacity: 0.6 !important;
}

a.btn-danger.disabled {
    background-color: #F44336 !important;
    border-color: #F44336 !important;
    opacity: 0.6 !important;
}

a.btn-primary.disabled {
    background-color: #2196F3 !important;
    border-color: #2196F3 !important;
    opacity: 0.6 !important;
}

.page-content a.btn i,
.page-content button.btn i {
    position: relative;
    top: -2px;
}

.page-content a.btn,
.page-content button.btn {
    padding: 5px 15px;
    font-size: 13px;
    min-width: 100px;
    border-radius: 4px;
}

body {
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
}

.vertical-collpsed .logo span.logo-sm {
    display: none;
}

.vertical-collpsed .logo span.logo-lg {
    display: block;
}

.vertical-collpsed .navbar-brand-box {
    width: 175px !important;
}

.navbar-header,
.navbar-brand-box,
.header-item {
    height: 48px;
    background-color: #37474F !important;
}

#sidebar-menu li.mm-active>a,
#sidebar-menu li.mm-active>a:hover {
    background-color: #26A69A;
}

.header-item,
.header-item:hover,
.topbar-right h4,
.noti-icon i {
    color: #fff;
}

.topbar-right h4 {
    padding: 9px;
    font-weight: 500;
}

.topbar-right .language-switch {
    height: 30px;
    color: #333;
    margin-top: 8px;
}

.brand-title {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    vertical-align: middle;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

.navbar-brand-box {
    padding: 0;
    width: 175px;
}

.navbar-brand-box .logo {
    float: left;
    padding: 0 0 0 8px;
    line-height: 46px;
}

.navbar-brand-box .logo img {
    margin-right: 6px;
    width: 40px;
    height: 41px;
}

.page-content {
    // padding: calc(24px + 24px) calc(24px / 2) 60px calc(24px / 24px);
    padding: calc(24px + 24px) 0 15px 0;
    // padding: 48px 0 15px 0;

}

.vertical-menu {
    top: 48px;
    background-color: #263238 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
    background-color: #263238 !important;
}

.header-profile-user {
    height: 30px;
    width: 30px;
}

.topbar-right {
    padding-right: 10px;
}

.topbar-right button {
    padding: 9px 15px;
}

.noti-icon .badge {
    top: 6px;
    right: 10px;
}

.grid-dropdown .dropdown-menu i {
    font-size: 32px;
}

.grid-dropdown .dropdown-menu {
    padding: 0.5rem 0 0;
}

.grid-dropdown .portal-icon i {
    color: #F4511E;
}

.grid-dropdown .finance-icon i {
    color: #03A9F4;
}

.grid-dropdown .mis-icon i {
    color: #66BB6A;
}

.grid-dropdown .helpdesk-icon i {
    color: #EF5350;
}

.grid-dropdown .dropdown-icon-item {
    line-height: 25px;
}

.grid-dropdown .col {
    border: 1px solid #ddd;
}

.grid-dropdown .row {
    margin-left: -8px;
    margin-right: -8px;
}

.notification-dropdown .noti-username {
    color: #2d6891;
    font-weight: 500;
}

.notification-dropdown .noti-date {
    color: #999999;
    font-size: 12px;
    line-height: 1.6666667;
    font-weight: 400;
    float: right;
}

.notification-dropdown .text-muted p {
    float: left;
}

.user-info-dropdown {
    width: 280px;
}

.user-info-dropdown .btn {
    border-radius: 3px;
}

.user-info-dropdown .my-profile,
.user-info-dropdown .logout {
    width: auto;
    display: inline-block;
}

.user-info-dropdown .logout {
    float: right;
}

.user-info-dropdown .logout:hover {
    background-color: #F44336;
}

.user-info-dropdown .my-profile:hover {
    background-color: #2196F3;
}

.user-info-dropdown .username,
.user-info-dropdown .designation,
.user-info-dropdown .email {
    display: block;
}

.user-info-dropdown .designation {
    text-transform: uppercase;
}

.user-info-dropdown .designation,
.user-info-dropdown .email {
    font-size: 11px;
    color: #999999;
}

.user-info-dropdown .username {
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    font-weight: 500;
}

.content-layout {
    padding: 0 20px 20px;
}

.page-title-box {
    flex-direction: column;
    align-items: flex-start !important;
    background: #fff;
    padding: 0.4rem 20px;
    padding: 0.4rem 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 1px 0 0 #ddd;
    box-shadow: 0 1px 0 0 #ddd;

}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
    letter-spacing: -0.015em;

}

h6 {
    font-size: 15px;
    font-weight: 400;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    font-weight: 400 !important;
    line-height: 1.5384616;
}

.breadcrumb-item>a,
.breadcrumb-item+.breadcrumb-item,
.breadcrumb-item.active,
.breadcrumb-item+.breadcrumb-item::before {
    color: #333;
}

.breadcrumb i {
    font-size: 12px;
    margin-right: 5px;
}

.menu-title {
    // color: rgba(255, 255, 255, 0.75);
    display: none !important;
}

.table thead.bg-primary {
    background-color: #26A69A !important;
}

.table-bordered th {
    border: 1px solid #90d0ca;
}

.table tbody td,
.table tfoot td {
    border: 1px solid #ccc;
}

.table-bordered th,
.table tbody td,
.table tfoot td {
    padding: 4px 8px !important;
}

.table .badge {
    width: 150px;
    padding: 2px;
    line-height: 1.5384616;
    letter-spacing: 0.1px;
    border-radius: 2px !important;
    font-size: 10px;
}

.badge-small {
    min-width: 75px !important;
    width: auto !important;
    padding: 2px 5px 2px 5px;
}

table.dataTable>tbody>tr:hover {
    background: rgba(0, 188, 212, 0.2);
}

table.dataTable {
    margin: 0 !important;
}

.input-group-text {
    border-radius: 0;
}

.form-select {
    background-position: right 0.35rem center;
    background-size: 12px 12px;
}

.form-control,
.input-group-text,
.form-select {
    height: 30px;
    padding: 2px 8px;
}

.table {
    background: #fff;
}

.table th {
    font-weight: normal;
}

.table thead.bg-secondary {
    background-color: #26a69a !important;
    border-color: #26a69a;
}

.table thead.bg-secondary th {
    padding: 6px 8px;
}

.table input[type="radio"] {
    accent-color: #17a2b8;
}

.card-header.bg-primary h5 {
    font-size: 15px;
    font-weight: 400;
}

.card-header.bg-primary {
    background-color: #00BCD4 !important;
    border-color: #00BCD4;
    padding: 5px 15px;
    font-size: 15px;
}

.pagination-rounded .page-link {
    width: 30px;
    height: 30px;
    border-radius: 3px !important;
    color: #757575;
}

.page-item.disabled .page-link {
    color: #757575;
    opacity: .35;
}

.page-item.active .page-link {
    background-color: #03a9f4;
    border-color: #03a9f4 !important;
}

.page-link {
    border: 1px solid #a8a8a8 !important;
    padding: 3px 8px 4px;
    min-height: 16px;
    min-width: 30px;
    border-radius: 3px;
    text-align: center;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #fff;
    background: #03a9f4;
    border-color: #03a9f4;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-align: center;
    margin: 0 1px;
    min-width: 30px;
    display: inline-block;
    min-height: 16px;
    padding: 3px 8px 4px;
    color: #757575;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #a8a8a8;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #fff;
    background: #03a9f4 !important;
    border-color: #03a9f4 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    opacity: .35;
    cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    color: #fff;
    background: #03a9f4;
    border-color: #03a9f4;
}

// .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
//     padding-right: 4px;
// }
// .dataTables_wrapper .dataTables_paginate .paginate_button.next {
//     padding-left: 4px;
// }
// .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
//     background: none;
// }
// .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
//     border: 1px solid transparent !important;
// }
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover .page-link {
    background: #03a9f4;
    border: 1px solid #03a9f4 !important;
    opacity: 0.35;
    color: #fff !important;
}
.withoutbordertable .dataTables_wrapper{
    border: none !important;  
}
// input[type="date"] {
//     // border-radius: 0;
// }
.dataTables_wrapper .table.dataTable,
table.dataTable {
    margin-top: 0 !important;
}

// .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):hover {
//     border: 1px solid transparent;
// }
.dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled) .page-link:hover {
    background: #03a9f4;
    border: 1px solid #03a9f4 !important;
    color: #fff;
}

#mydatatable_wrapper>.row {
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
}

.dataTables_wrapper .dataTables_info {
    font-weight: bold;
}

.dataTables_wrapper {
    background-color: #fff;
    border: 1px solid #ddd;
    display: inline-block;
    width: 100%;
}

.pagination {
    margin-bottom: 7px;
}

// table.dataTable thead > tr > th.sorting:first-child:before,
// table.dataTable thead > tr > th.sorting:first-child:after,
// table.dataTable thead > tr > th.sorting:last-child:before,
// table.dataTable thead > tr > th.sorting:last-child:after {
//     content: "";
// }
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after {
    opacity: 1 !important;
    font-size: 0.6em !important;
    right: 4px !important;
}

.DP {
    margin-left: 12px;
}

table.dataTable thead>tr>th.sorting:before {
    bottom: 75% !important;
}

table.dataTable thead>tr>th.sorting:after {
    top: 20% !important;
}

#mydatatable_wrapper {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
}

.dataTables_wrapper .dataTables_paginate {
    margin-bottom: 6px !important;
    float: right;
}

.dataTables_wrapper .dataTables_length {
    float: right;
    margin: 1px 10px 0;
}

.dataTables_wrapper .dataTables_info {
    clear: none;
    float: right;
    color: #757575;
    padding-right: 5px;
    padding-top: 6px !important;
}

.dataTables_wrapper .dataTables_length select {
    width: 45px !important;
    height: 26px;
}

footer a {
    color: #2d6891;
}

.footer {
    padding: 8px 20px;
    position: fixed;
    left: 0 !important;
    bottom: 0;
    min-height: 36px;
    height: 36px;
    border: 1px solid #ddd;
    border-bottom-color: #fff;
    border-left: 0;
    border-right: 0;
    background-color: #fff;
    z-index: 9999;
}

@media only screen and (max-width: 768px) {
    .md-mb-3 {
        margin-bottom: 1rem !important;
    }
}

@media only screen and (max-width: 575px) {
    .xs-mb-3 {
        margin-bottom: 1rem !important;
    }

    .cust-vertical-wizard {
        .stepper {
            flex-direction: column;

            a .stepper-header {
                width: 100% !important;
            }

            .stepper-body {
                .content {
                    section {
                        padding-left: 0rem !important;
                        padding-top: 1rem;
                    }
                }

            }
        }
    }
}

//Balachandar//

.card-body {
    padding: 15px !important;
    border: 1px solid #ddd;
}

#sidebar-menu {
    padding: 0px !important;
}

.table> :not(caption)>*>* {
    background-color: transparent;
}

app-page-title+div,
app-page-title+div+div {
    padding: 0 20px !important;
}

.page-scroll {
    max-height: 450px;
    overflow-y: auto;
}

.page-title-box h4 {
    text-transform: capitalize;
    font-size: 19px !important;
}

.mandatory-text {
    font-size: 12px;
    margin-right: 15px;
}

.panel.accordion-item.panel-open .card-header,
.panel-heading {
    background-color: #00BCD4;
 
    padding: 5px 15px;
}


.panel-group .card .card-header .panel-title .btn-link,
.panel-group .card .card-header {
    color: #fff;
}

.panel-group .card .card-header {
    padding: 5px 15px;
    font-size: 15px;
}

.panel-group .card .card-header .btn {
    font-size: 15px;
    font-weight: 400 !important;
}

.btn,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

.passwordprogress {
    display: flex;
    height: 5px;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.wizard .stepper-header>ol li.active .number {
    background-color: #fff;
    border: 2px solid #ff5722 !important;
    color: #ff5722;
}

.wizard .stepper-header>ol li .success-process {
    // width: 38px;
    // height: 38px;
    border-radius: 50%;
    // margin-top: 5px;
    padding: 9px 12px;
    background-color: #4caf50;
    border: 2px solid #4caf50 !important;
    color: #fff;
}

// .wizard .stepper-header > ol li.active span {
//     color:#000 ;
// }

.wizard .stepper-header>ol li .number {
    background-color: #fff;
    border: 2px solid #bbb !important;
    color: #bbb;
}

.wizard .stepper-header>ol>li {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 0.5rem 1rem;
    color: var(--bs-gray-600);
    font-weight: 500;
    background-color: transparent !important;
    flex-direction: column;
}

.stepper-header .steps {
    width: 100%;
    list-style: none;
    display: inline-flex;
    margin: 0 auto;
    align-items: flex-start !important;
}

.Staff-innercard {
    border-top: 2px solid #26a69a;
    box-shadow: 1px 4px 2px 1px #ccc;
}

// .stepper-body
// {
// // display: none;
// }
.StaffEligibilityStatementhzline {
    border-top: 2px solid #bbb !important;
    position: absolute;
    top: 25px;
    left: 23px;
    width: 95%;
}

.stepper-body[_ngcontent-ng-c625643793] {
    margin: 0px !important;
    padding: 0px !important;
}

.btn-primary:disabled {
    background-color: #2196F3 !important;
    border-color: #2196F3;
    opacity: 0.6 !important;
}

.uploadfinename {
    font-size: 10px !important;
    color: #000;
}

label {
    font-weight: 400 !important;
    margin-bottom: 0.5rem;
    color: #333;
}

.input-group-text i {
    color: #666;
}

.table button {
    min-width: auto !important;
}

.calend-icon ::before {
    content: "\f073 ";
    right: 0px;
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    top: 0px;
    background: #00BCD4;
    padding: 4px 9px;
    color: #fff;
    border: none;
    border-radius: 0 0px 0px 0;
    width: 30px;
    height: 30px;
    z-index: -999;
}

.form-group_calend input {
    width: 100%;
    position: relative;
    z-index: 9;
    background: #fff;
    padding: 0px 0px 0px 8px !important;
    cursor: pointer;
}

.form-group_calend input::-webkit-calendar-picker-indicator {
    opacity: 0;

}

.form-group_calend input[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
}

.calend-icon {
    width: 100%;
    position: relative;
    display: block;
    // border: 1px solid #ddd;
    // border-radius: 4px 0px 0px 4px;

}

.border-bot {
    border-bottom: 1px solid rgba(80, 84, 88, 0.2) !important;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.view-page h4 {
    font-size: 17px !important;
}

.add-page h4 {
    font-size: 17px !important;
}

.messagebundletextbox {
    padding: 5px 8px;
    border: 1px solid rgb(189, 195, 201);
    width: 100%;
    border-radius: 4px;
    resize: none;
}

// .disabled-icon {
//     cursor: not-allowed !important;
//     pointer-events: auto !important;
// }
.label-default {
    background-color: #999999;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
    padding: 6px 12px;
    background: #00bcd4;
    color: #fff;
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
    color: #fff;
}

textarea.form-control {
    min-height: 30px !important;

}

textarea {
    resize: none;
}

input[type=file]::file-selector-button {
    display: none;
}

input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

input[type=file] {
    margin-right: 15px;
    // color: transparent;
}

input[type=file]+label i {
    padding-right: 5px;
}

input[type=file]+label:hover {
    cursor: pointer;
}

input[type=file]+label {
    border-radius: 3px;
}

input[type=file]+label,
input[type=file]+label i {
    color: #fff;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    // width: 2rem;
    // height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-color: #333;
    border-radius: 50px;
    padding: 13px;
    background-size: 35px 20px;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.border_carousel {
    border: 1px solid #ddd;
    padding: 10px;
    border-color: #FF5722;
}

.text-primary {
    color: #2196F3;
}

// .btn:disabled, .btn.disabled, fieldset:disabled .btn {
//     color: var(--bs-btn-disabled-color);
//     pointer-events: none;
//     background-color: #2196F3 !important;
//     border-color: #2196F3 !important;
//     opacity: var(--bs-btn-disabled-opacity);
// }
// input[type=date] {
//    border-radius: 4px;
// }
.scrolly {
    overflow-y: auto;
    height: 400px;
}

input[type=date] {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    // border: none;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: left !important;
}

.page-scroll {
    max-height: 450px;
    overflow-y: auto;
}

.form-control:disabled,
input:disabled,
select:disabled {
    background-color: #f4f4f4 !important;
    border: 1px solid #bdc3c9;
    opacity: 0.6 !important;
}

.form-control:disabled,
input:disabled,
select:disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

// .table-scroll{
//     height: 200px;
//     display: inline-block;
//     overflow-y: auto;
// }
.btn-min-w-auto {
    min-width: auto !important;
}

.cust-vertical-wizard {
    .stepper {
        .stepper-header {
            width: 25%;

            ol {
                border: 1px solid #ddd;

                li {
                    min-width: 100% !important;
                    background: #ffffff !important;
                    border-bottom: 1px solid #ddd;
                }

                li.active {
                    background-color: #53d8cc !important;
                    color: #fff !important;
                }
            }
        }

        .stepper-body {

            .content.active {
                transform: none !important;
            }

            .content {
                section {
                    padding-left: 1rem;
                }
            }

        }
    }
}

.cust-dropzone {
    dropzone {
        background: #e7e7e7 !important;
        padding: 0.675rem;
        min-height: 210px !important;

        .dz-wrapper {
            background: #F1F1F1 !important;
        }

    }
}

.bg-stepper {
    .card-body {
        padding: 0px !important;

        .stepper-header {
            padding: 15px;
            background: #26A69A;

            ol li.active .number {
                background-color: #2196F3;
                border: 2px solid #ffffff !important;
                color: #ffffff;
            }

            ol li .number {
                // background-color: #2196F3;
                border: 2px solid #bbbbbb !important;
                color: #c8c8c8;
            }
        }

        // .stepper-body {
        //     // padding: 15px !important;
        // }
    }

}

.profilepswd {
    background: #FF5722 !important;
    border-color: #FF5722;
}

.panel-body {
    padding-left: 15px;
}

.form-group {
    color: #08A1B4;
}

.panel {
    background-color: #fff !important;
    border-radius: 3px !important;
}

.cust-comment-tab {
    ul {
        li {
            flex-grow: 0 !important;
        }
    }
}

.bg-table {
    background-color: #e4e4e4 !important;
}

.bg-brown {
    background-color: #795548;
    color: #ffffff;
}

.icon-text-size {
    font-size: 30px;
}

.text-muted-custom {
    font-size: 10px;
    color: #999;
}

.text-new-custom {
    font-size: 14px;
    color: #000;
}

.btn.bg-teal-400 {
    background-color: #26A69A;
    border-color: #26A69A;
    color: #fff;
    border-radius: 20px !important;
}

.btn.bg-teal-400 i {
    top: 0px !important;
}

.panel-header-title .panel-heading {
    border: 1px solid #ddd;
    border-radius: 0;
    background-color: #f3f3f3 !important;
}

.panel-heading {
    padding: 5px 15px;
}

.bg-brown-400 {
    background-color: #8D6E63;
    border-color: #8D6E63;
    color: #fff;
    border-radius: 20px !important;
}

.bg-brown-400 i {
    top: 0px !important;
}

.avatar-sm {
    height: 2rem;
    width: 2rem;
}

.icon-size-custom {
    font-size: 18px;
}

.custom-icon-color {
    border-radius: 4px;
    background-color: #607D8B !important;
}

.custom-icon-color1 {
    border-radius: 4px;
    background-color: #2196F3 !important;
}

.custom-icon-color2 {
    border-radius: 4px;
    background-color: #00BCD4 !important;
}

.custom-icon-color3 {
    border-radius: 4px;
    background-color: #673AB7 !important;
}

.custom-icon-color4 {
    border-radius: 4px;
    background-color: #795548 !important;
}

.update-nag {
    display: inline-block;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    height: 35px;
    width: 100%;
    border: 1px solid #bdc3c9;
    box-shadow: none;
    margin-bottom: 10px;
    cursor: default;
}

.update-nag>.update-split.update-success {
    background: #5cb85c !important;
}

.update-nag>.update-split.update-warning {
    background: #FFB64D !important;
}

.update-nag>.update-split.update-primary {
    background: #4680ff !important;
}

.update-nag>.update-split.update-danger {
    background: #d9534f !important;
}

.update-nag>.update-split {
    width: 33px;
    float: left;
    color: #fff !important;
    height: 100%;
    text-align: center;
    cursor: default;
}

.update-nag>.update-text {
    line-height: 20px;
    padding-top: 6px;
    padding-left: 40px;
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    cursor: default;
}

.text14 {
    font-size: 14px;
}

.text-success,
.text-success:hover,
.text-success:focus {
    color: #4CAF50 !important;
}

.pull-right {
    float: right !important;
}

.update-nag .icon-top {
    margin-top: 10px;
}

.btn-labeled>b {
    position: absolute;
    top: -1px;
    left: 12px;
    background-color: rgba(0, 0, 0, 0.15);
    display: block;
    line-height: 1;
    padding: 10px;
}

.btn-rounded.btn-labeled>b {
    border-radius: 100px;
}

.btn-labeled {
    padding-left: 48px;
}

.dataTables_scrollBody thead {
    border: none;
    visibility: collapse;
}

.background-card {
    background-color: #26A69A !important;
}

.logo-new-width {
    width: 75px;
}

.price-popup-table tbody tr td,
.price-popup-table tfoot tr td {
    padding: 5px;
}

.price-popup-table tfoot tr {
    border-top: 1px solid #ccc;
}

.salesquotation_carousel {
    border: 1px solid #ddd;
    padding: 10px;
    /* border-color: #FF5722; */
    border-top: 1px solid #ff5722;
}

.preview-table th {
    background-color: #999;
    // border: 1px solid #ccc !important;
    vertical-align: middle;
    text-align: left !important;
}

.preview-table td {
    // border: 1px solid #ccc !important;
    vertical-align: middle;
}

.noborder-table td {
    color: #333;
    border: none !important;
    padding: 5px !important;
    background: #fff;
}

.purchase-tab1 ul li:first-child a span:before {
    content: "\f164";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    font-size: 15px;
    margin-right: 3px;
}

.purchase-tab1 ul li:last-child a span:before {
    content: "\f165";

    font-family: "Font Awesome 5 free";
    font-weight: 900;
    font-size: 15px;
    margin-right: 3px;
}

.dataTables_scrollBody thead {
    border: none;
    visibility: collapse;
}

.yarn-icon {
    font-size: 48px !important;
    color: #00BCD4;
}

.yarn-icon-thumbs-up {
    font-size: 48px !important;
    color: #4CAF50 !important;
}

.yarn-icon-hourglass-half {
    font-size: 48px !important;
    color: #2196F3 !important;
}

.yarn-icon-hand-point-right {
    font-size: 48px !important;
    color: #F44336 !important;
}

.yarn-icon-border {
    border-right: 1px solid #ccc;
}

.yarn-icon.icon-change-yarn {
    font-size: 15px !important;
    color: #2196F3 !important;
}

.yarn-icon1.icon-change-yarn1 {
    font-size: 15px !important;
    color: #4CAF50 !important;
}

.yarn-icon2.icon-change-yarn2 {
    font-size: 15px !important;
    color: #FF5722 !important;
}

.icon-border {
    border: 2px solid;
    border-radius: 20px;
    padding: 5px 8px;
}

.timer-btn {
    min-width: auto !important;
    padding: 3px 4px 1px 4px;
}

.radio-input-box {
    border: 1px solid #ced4da;
    padding: 4px;
    border-radius: 3px;
}

.table-full-width table {
    width: 100% !important;
}
.progress {
    display: inline-block;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #ccc;
    border-radius: 20px;
    width: inherit;
}

.progress-bar {
    color: #fff;
    text-align: center !important;
    transition: width .6s ease;
    color: #7e7e7e !important;
    font-weight: bold;
    background-color: #ccc !important;
}

.progress.finalplanbar1 {
    background-color: #f90 !important;
}

.progress-bar.finalplanbar {
    background-color: #f90 !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #333333 !important;
}

.nav-tabs-custom .nav-item .nav-link {
    color: #888 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
    background-color: #F06292 !important;
}

.nav-tabs-custom .nav-item .nav-link:hover {
    color: #333333 !important;
}

// ASIR DURAI SELVAN CSS
.product-col-icon-border.text-muted {
    font-size: 13px;
}

.product-col-icon-all-border {
    border: 1px solid #00BCD4;
    padding: 15px 15px 15px 15px;
}

.product-col-icon-border {
    border-right: 1px solid #00BCD4;
}

.product-col-icon-layer-group {
    color: #00BCD4;
    font-size: 30px;
}

.product-col-icon-check-circle {
    font-size: 32px;
    color: #4CAF50;
}

.product-col-icon-hourglass-half {
    font-size: 32px;
    color: #FF5722;
}

.product-col-icon-arrow-alt-circle-right {
    font-size: 32px;
    color: #607D8B;
}

.product-col-icon-list {
    font-size: 30px;
    color: #00BCD4;
}

.product-col-icon-thumbs-up {
    font-size: 30px;
    color: #4CAF50;
}

.product-col-icon-thumbs-down {
    font-size: 30px;
    color: #FF5722;
}

.product-col-icon-check-circle-last {
    font-size: 32px;
    color: #607D8B;
}

.form-heading-underline {
    border-bottom: 1px solid rgba(80, 84, 88, 0.2) !important;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.form-heading-upperline {
    border-top: 1px solid rgba(80, 84, 88, 0.2) !important;
    padding-top: 10px;
    margin-bottom: 5px;
}

.view-style .input-group span,
.view-style .input-group p,
.view-style .input-group span label {
    color: #08A1B4;
    font-weight: 500;
    display: block;
    word-wrap: break-word;
}

table tbody tr td span a .btn {
    min-width: 45px;
    height: 20px;
}

// ARULANADHAN CSS
.invoice-top-table td {
    color: #333;
    border: none !important;
    padding: 2px 6px !important;
}

.invoice-top-table tr:hover,
.challan-table tr:hover {
    background: none;
}

.invoice-card-table td {
    width: 50%;
    text-wrap: wrap;
}

.green-selectbox {
    background-color: #e8f5e9;
    color: #43a047;
}

.red-selectbox {
    background-color: #fbe9e7;
    color: #d84315;
}

.invoice-cards .card {
    height: 100%;
}

.challan-table td {
    text-wrap: wrap;
    color: #333;
    border: none !important;
    padding: 2px 6px !important;
}
.gap {
    height: 40px;
}
.dataTables_scrollHeadInner{
    width: 100% !important;
}
.dataTables_wrapper .table.dataTable, table.dataTable {
    margin-top: 0 !important;
    // width: 100vw !important;
}
.mdi-minus::before {
    content: "\f0374";
    color: white;
}
.mdi-plus::before {
    content: "\f0374";
    color: white;
}


.empregister-verticaltab ul li .nav-link.active {
    // background-color: #26A69A !important;
    width: 238px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color:white;
    background-color:#53D8CC!important;
    border-radius:0px !important;
    border-bottom:none !important;
    text-align: left;
}

.nav-pills .nav-link{
color: #454545;
border-bottom: 1px solid #ddd;
text-align: left;
}

.nav-pills
{
    border: 1px solid #ddd;
}
.tab-scroll
{
    max-height: 350px;
    overflow:scroll;
}

.btn
.btn-brown {
    background: #795548 !important;
    border-color: #795548 !important;
    color: #fff;
}

.btn-brown,
.btn-brown:hover {
    background: #795548 !important;
    border-color: #795548 !important;
    color: #fff;
}

button.btn.btn-orange {
    background-color: #FF5722;
    color: #fff;
}
.dlt-btn
{
    position: relative !important;
    bottom: 6px !important;
}
.main-content {
    overflow: visible;
}
.dropdown-list ul:last-child {
    max-height: 160px !important;
}



.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
    display: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
    width: auto;
}


.bs-datepicker-body table td span.highlight-today {
    background-color: #61a6f5 !important;
    color: #fdfdfd !important;
    border-radius: 0%;
  }